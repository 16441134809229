import React from "react";

function ColorBox({ color = "black", isSelected = false, selectColor }) {
  return (
    <div
      className={`h-8 w-8 bg-${color} text-white border border-primary  flex items-center justify-center`}
      onClick={() => selectColor(color)}
    >
      {isSelected && "✔"}
    </div>
  );
}

export default ColorBox;
