import React, { useEffect, useState } from "react";
import { Categories } from "../components/common/Categories";
import { useAuth } from "../context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import { getGalleryByCategory } from "../network/CollectionsAPI";
import { useLoader } from "../context/LoaderContext";
import Navbar from "../components/layout/Navbar";
import BackButton from "../components/common/BackButton";
import Footer from "../components/layout/footer";

function FacemashCollection() {
  const { title } = useParams();
  const { user, login, setIsLoginPopUpOpen } = useAuth();
  const { loader, setLoader, Spinner } = useLoader();
  const navigate = useNavigate();
  const [avatars, setAvatars] = useState([]);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData === null) {
      setIsLoginPopUpOpen(true);
      navigate("/home");
    } else {
      setLoader(true);
      const getAvatarsByCategory = async (userData, category) =>
        await getGalleryByCategory(userData, category);
      getAvatarsByCategory(userData, title)
        .then((result) => {
          const data = result.data.data.filter(
            (item) => item.category === title
          );
          setAvatars(data);
          setLoader(false);
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    }
  }, []);

  return loader === true ? (
    <Spinner></Spinner>
  ) : (
    <>
      <Navbar>
        <BackButton size={30} />
        <h2 className="flex-1 text-base text-black  font-[400]  font-raleway">
          {title.toLowerCase()}
        </h2>
      </Navbar>
      <div className="m-4">
        <Categories
          data={avatars}
          title={title}
          categories={["all", "male", "female", "your favorites"]}
          add={true}
          showFavIcon={true}
        />
      </div>
      <Footer />
    </>
  );
}

export default FacemashCollection;
