import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;

function getBannerImgs() {
  const url = `${baseUrl}/getBannerImages`;
  return axios.get(url);
}

export { getBannerImgs };
