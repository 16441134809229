import React, { useState, useEffect } from "react";
import { FaMinus, FaPlus } from "react-icons/fa";

const Cartitem = ({ data = {}, productsHandler = () => {} }) => {
  useEffect(() => {
    console.log("product data in cart item", data);
  }, []);

  const incrementQuantity = () => {
    const updatedData = { ...data, quantity: data.quantity + 1 };
    productsHandler(updatedData);
  };

  const decrementQuantity = () => {
    const updatedData = { ...data, quantity: data.quantity - 1 };
    productsHandler(updatedData);
  };
  return (
    data !== undefined && (
      <div>
        <div className="bg-[#F7F7F7]  p-2 flex flex-col gap-4  ">
          <div className="grid grid-cols-3 gap-2">
            <div className="col-span-1 ">
              <img src={data.product_url} alt="" />
            </div>
            <div className="col-span-1 flex  justify-center  flex-col gap-1">
              <h1 className="text-primary text-[18px] font-medium ">
                {data.name}
              </h1>
              <h1 className="text-[18px] font-bold  ">{`${data.price} USD*`}</h1>
              <div className=" flex  justify-around border w-[70%] py-[2px] border-primary rounded-md">
                <button className="text-primary" onClick={decrementQuantity}>
                  <FaMinus size={10} />
                </button>
                <span className="text-primary">{data.quantity}</span>
                <button className="text-primary" onClick={incrementQuantity}>
                  <FaPlus size={10} />
                </button>
              </div>
            </div>

            <div className="col-span-1  flex flex-col gap-1">
              <img src={data.facemash_img_url} alt="" />
              <h1 className=" text-[#595959] md:text-[16px] text-[14px]">
                {data.caption}
              </h1>
            </div>
          </div>

          <div className="text-center font-normal text-[12px] ">
            Item ships in 3-4 business days from our warehouse.
          </div>
        </div>
      </div>
    )
  );
};

export default Cartitem;
