import React, { useEffect, useState } from "react";
import { TbUpload } from "react-icons/tb";
import { useLocation, useNavigate } from "react-router-dom";
import { uploadToFaceMash } from "../../network/FacemashAPI";
import { useAuth } from "../../context/AuthContext";
import { useFaceMash } from "../../context/FaceMashContext";
import { useLoader } from "../../context/LoaderContext";
import mergeImages from "merge-images";

const UploadImg = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const { targetImg, setTargetImg, srcImg, setSrcImg, setFaceMashImg } =
    useFaceMash();
  const { loader, setLoader, Spinner } = useLoader();
  const [loading, setLoading] = useState(false); // State to track loading status

  useEffect(() => {
    setSrcImg("");
    if (localStorage.getItem("targetImg")) {
      setTargetImg(JSON.parse(localStorage.getItem("targetImg")));
    }
  }, []);

  const isImage = (file) => {
    const acceptedImageTypes = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/bmp",
      "image/webp",
      "image/tiff",
      "image/x-icon",
      "image/svg+xml",
    ];
    return acceptedImageTypes.includes(file.type);
  };

  const imagesMergeHelper = async (watermarkUrl, facemashUrl) => {
    // Function to resize image
    const resizeImage = (image, maxWidth, maxHeight, circular = false) => {
      const aspectRatio = image.width / image.height;
      let newWidth = maxWidth;
      let newHeight = maxHeight;

      if (aspectRatio > 1) {
        newHeight = Math.min(image.height, maxHeight);
        newWidth = newHeight * aspectRatio;
      } else {
        newWidth = Math.min(image.width, maxWidth);
        newHeight = newWidth / aspectRatio;
      }

      const canvas = document.createElement("canvas");
      canvas.width = newWidth;
      canvas.height = newHeight;
      const ctx = canvas.getContext("2d");

      if (circular) {
        // Create circular mask
        ctx.beginPath();
        ctx.arc(
          newWidth / 2,
          newHeight / 2,
          Math.min(newWidth, newHeight) / 2,
          0,
          Math.PI * 2
        );
        ctx.closePath();
        ctx.clip();
      }

      ctx.drawImage(image, 0, 0, newWidth, newHeight);

      return canvas.toDataURL();
    };

    const fetchImageAsBlob = async (imageUrl) => {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      return blob;
    };

    const watermarkBlob = await fetchImageAsBlob(watermarkUrl);

    const facemashBlob = await fetchImageAsBlob(facemashUrl);

    const watermarkImage = new Image();
    const facemashImage = new Image();

    watermarkImage.src = URL.createObjectURL(watermarkBlob);
    facemashImage.src = URL.createObjectURL(facemashBlob);

    await Promise.all([
      new Promise((resolve) => {
        watermarkImage.onload = resolve;
      }),
      new Promise((resolve) => {
        facemashImage.onload = resolve;
      }),
    ]);

    // Resize product image and facemash image

    const resizedWatermarkUrl = resizeImage(watermarkImage, 360, 360);
    const resizedFacemashUrl = resizeImage(facemashImage, 360, 360); // Make facemash image circular

    mergeImages([{ src: resizedFacemashUrl }, { src: resizedWatermarkUrl }])
      .then((b64) => {
        setFaceMashImg(b64);
      })
      .catch((err) => console.log(err));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (!file || !isImage(file)) {
      console.error("Invalid file type. Please select a valid image.");
      return;
    }
    setSrcImg(file);
    setLoading(true); // Set loading to true when file is being uploaded
    setLoader(true);
    uploadImageToCloud(user, targetImg, file)
      .then((res) => {
        console.log(res);
        const facemashUrl = res.data.data.aws_url;
        const watermarkUrl = "/images/watermark.png";
        // console.log(watermarkUrl);
        imagesMergeHelper(watermarkUrl, facemashUrl)
          .then(() => {
            setLoader(false);
            navigate("/merch");
          })
          .catch((err) => {
            console.log(err);
            setLoader(false);
          });
        // setFaceMashImg(res.data.data.aws_url);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      })
      .finally(() => setLoading(false)); // Set loading to false when upload is completed
  };
  const fileToBlob = async (file) => {
    const blob = await new Response(file).blob();
    return blob;
  };

  const uploadImageToCloud = async (user, targetInputFile, file) => {
    const response = await fetch(targetInputFile);
    const blob = await response.blob();
    const targetFile = new File([blob], "target_image.jpeg", {
      type: blob.type,
    });
    targetInputFile = targetFile;
    setFaceMashImg(null);
    return await uploadToFaceMash(user, targetInputFile, file);
  };

  const buttonClickHandler = () => {
    const input = document.getElementById("uploadImg");
    input.click();
  };

  return loader === true ? (
    <Spinner time={29}></Spinner>
  ) : (
    <div className="flex flex-col gap-3" id="upload">
      <div className="flex flex-row gap-4">
        <div className="flex flex-1 flex-col items-center gap-2">
          <p className="text-[13px] font-[400]">your selected avatar</p>
          <img
            src={targetImg}
            alt="Selected"
            className="w-32 h-32 sm:w-44 sm:h-44 p-2 object-cover border border-primary"
            id="source-image-preview"
          />
        </div>
        {srcImg ? (
          <div className="flex flex-1 flex-col items-center gap-2">
            <p className="text-[13px] font-[400]">your image</p>
            <img
              src={URL.createObjectURL(srcImg)}
              alt="Selected"
              className=" w-32 h-32 sm:w-44 sm:h-44 p-2 object-cover border border-primary"
              id="target-image-preview"
            />
          </div>
        ) : (
          <div
            className="flex flex-1 flex-col items-center justify-center gap-2"
            onClick={buttonClickHandler}
          >
            {loading ? (
              <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-300 bg-opacity-50 z-50">
                <div className="animate-spin rounded-full h-20 w-20 border-b-2 border-pk"></div>
              </div>
            ) : (
              <button className=" flex bg-pk text-white rounded-sm p-2">
                <span className="text-center text-[14px] font-[600]">
                  upload photo
                </span>
                <TbUpload className="h-5 w-8 md:h-6 md:w-10" />
              </button>
            )}
            <input
              type="file"
              id="uploadImg"
              onChange={handleFileChange}
              accept="image/*" // Restrict file selection to images
              className="hidden cursor-pointer w-[200px] h-[180px] object-fit "
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadImg;
