import React from "react";

const Hoverbutton = ({ text, customClassName, onClick }) => {
  return (
    <div>
      <button
        className={" h-[40px] w-full   " + customClassName}
        onClick={onClick}
      >
        {text}
      </button>
    </div>
  );
};

export default Hoverbutton;
