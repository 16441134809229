import React from "react";
import Logo from "../common/Logo";
import { IoLogoInstagram } from "react-icons/io";
import { FaDiscord } from "react-icons/fa";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div>
      <hr className="border-t-pk border-t-1"></hr>
      <div className="flex flex-col p-5 gap-2 items-center">
        <Logo />
        <div className="flex flex-wrap m-auto max-w-xs md:max-w-md text-center">
          <p>artefax.ai is a print on demand (POD) platform powered by AI.</p>
        </div>
        <div className="text-pk m-auto flex flex-row gap-2">
          <IoLogoInstagram className="text-3xl cursor-pointer" />
          <FaDiscord className="text-3xl cursor-pointer" />
        </div>
        <hr className="border-pk border-1 w-2" />
        <Link to="/">about us</Link>
        <Link to="/privacypolicy">privacy policy</Link>
        <Link to="/">terms & conditions</Link>
        <Link to="/">refund & refund policy</Link>
        <hr className="border-pk border-1 w-2"></hr>
      </div>
    </div>
  );
}

export default Footer;
