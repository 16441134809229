// import React, { createContext, useContext, useState } from 'react';

// const AuthContext = createContext();

// export const useAuth = () => useContext(AuthContext);

// export const AuthProvider = ({ children }) => {
//   const [user, setUser] = useState(null);

//   const login = (userData) => {
//     setUser(userData);
//   };

//   const logout = () => {
//     setUser(null);
//   };

//   const value = {
//     user,
//     login,
//     logout,
//   };

//   return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
// };
// AuthContext.js

import React, { createContext, useContext, useState } from "react";
import LoginPopUp from "../components/modals/LoginPopUp";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [itemQuantity, setItemQuantity] = useState(1); // Assuming initial quantity is 1
  const [isLoginPopUpOpen, setIsLoginPopUpOpen] = useState(false);

  const login = (userData) => {
    setUser(userData);
    if (userData !== null) setIsLoginPopUpOpen(false);
  };

  const logout = () => {
    setUser(null);
  };

  const value = {
    user,
    login,
    logout,
    LoginPopUp,
    isLoginPopUpOpen,
    setIsLoginPopUpOpen,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
