import React, { useEffect, useState } from "react";
import Cartitem from "./Cartitem";
import InputBox from "./InputBox";
import BackButton from "./BackButton";
import Dropdown from "./Dropdown";
import { useCart } from "../../context/CartContext";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import Navbar from "../layout/Navbar";
import Footer from "../layout/footer";

const schema = yup.object({
  contactemail: yup
    .string()
    .required("* email is required")
    .email("email is not valid"),
  email: yup
    .string()
    .required("* email is required")
    .email("email is not valid"),
  firstname: yup.string().required("* first name is required"),
  lastname: yup.string().required("* last name is required"),
  country: yup.string().required("* country is required"),
  addressLine1: yup.string().required("* address line 1 is required"),
  addressLine2: yup.string(),
  city: yup.string().required("* city is required field"),
  state: yup.string().required("* state is required field"),
  zipcode: yup.string().required("* zip code is required field"),
  phonenumber: yup
    .string()
    .required("* phone number is required field")
    .matches(
      /^\+?[0-9]{1,3}?[-. ]?\(?[0-9]{3}\)?[-. ]?[0-9]{3}[-. ]?[0-9]{4}$/,
      "phone number is not valid"
    ),
});

const CheckCart = () => {
  const [deliveryOption, setDeliveryOption] = useState("Standard");
  const { checkOutProducts, setCheckOutProducts, setPaymentData } = useCart();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const checkoutProductsLocal = JSON.parse(
      localStorage.getItem("checkoutproducts")
    );
    console.log(checkoutProductsLocal);
    setCheckOutProducts(checkoutProductsLocal);
  }, []);

  const productsPrice = checkOutProducts.reduce(
    (acc, item) => acc + item.price * item.quantity,
    0
  );
  const shippingPrice = 30;
  const totalPrice = productsPrice + shippingPrice;

  const handleOptionSelect = (option) => {
    setDeliveryOption(option);
  };

  const formSubmitHandler = (data) => {
    const paymentData = {
      ...data,
      deliveryOption: deliveryOption,
      products: checkOutProducts,
      totalprice: totalPrice,
    };
    console.log("Data", paymentData);
    setPaymentData(paymentData);
    navigate("/payment");
  };

  const checkOutProductsHandler = (singleItem) => {
    if (checkOutProducts.length > 0) {
      if (singleItem.quantity > 0) {
        const updatedCheckoutProducts = checkOutProducts.map((product) => {
          if (product && singleItem && product.id === singleItem.id) {
            return {
              ...product,
              quantity: singleItem.quantity,
            };
          } else return product;
        });
        setCheckOutProducts(updatedCheckoutProducts);
        localStorage.setItem(
          "checkoutproducts",
          JSON.stringify(updatedCheckoutProducts)
        );
      } else {
        const updatedCheckoutProducts = checkOutProducts.filter((product) => {
          if (product && singleItem && product.id !== singleItem.id) {
            return {
              ...product,
              quantity: singleItem.quantity,
            };
          }
        });
        setCheckOutProducts(updatedCheckoutProducts);
        localStorage.setItem(
          "checkoutproducts",
          JSON.stringify(updatedCheckoutProducts)
        );
      }
    }
  };
  const handlePayNowClick = (event) => {
    if (checkOutProducts.length === 0) return;
    setPaymentData(checkOutProducts);
    event.preventDefault();
    handleSubmit(formSubmitHandler)();
  };

  return (
    <div>
      <Navbar>
        <div className="flex-1">
          <BackButton />
          <p className="text-base text-black">checkout</p>
        </div>
      </Navbar>
      <div>
        <div className=" flex flex-col  gap-4 m-4">
          {/* <form onSubmit={handleSubmit(formSubmitHandler)}> */}

          <div className="flex flex-col gap-2">
            <h1 className="text-primary  text-[18px] font-[700]">Contact</h1>
            <InputBox
              customClassName="border border-[#B2B2B2]  rounded-md bg-[#F7F7F7]"
              customPlaceholder="Email"
              register={{ ...register("contactemail") }}
              errorMessage={errors.contactemail?.message}
            />
          </div>
          <div>
            <h1 className="text-primary my-3 text-[18px] font-[700]">
              Delivery
            </h1>
            <div className="flex flex-col gap-4">
              <InputBox
                customClassName="border border-[#B2B2B2]   w-full rounded-md bg-[#F7F7F7]"
                customPlaceholder="Email"
                register={{ ...register("email") }}
                errorMessage={errors.email?.message}
              />
              <Dropdown
                title="Country"
                options={[
                  "India",
                  "Singapore",
                  "Russia",
                  "Pakistan",
                  "China",
                  "Japan",
                  "Malaysia",
                  "Sri Lanka",
                ]}
                register={{ ...register("country") }}
                errorMessage={errors.country?.message}
              />
              <InputBox
                customClassName="border border-[#B2B2B2]   w-full rounded-md bg-[#F7F7F7]"
                customPlaceholder="first name"
                register={{ ...register("firstname") }}
                errorMessage={errors.firstname?.message}
              />
              <InputBox
                customClassName="border border-[#B2B2B2]   w-full rounded-md bg-[#F7F7F7]"
                customPlaceholder="last name"
                register={{ ...register("lastname") }}
                errorMessage={errors.lastname?.message}
              />
              <InputBox
                customClassName="border border-[#B2B2B2]   w-full rounded-md bg-[#F7F7F7]"
                customPlaceholder="address line 1"
                register={{ ...register("addressLine1") }}
                errorMessage={errors.addressLine1?.message}
              />
              <InputBox
                customClassName="border border-[#B2B2B2]   w-full rounded-md bg-[#F7F7F7]"
                customPlaceholder="address line 2"
                register={{ ...register("addressLine2") }}
                errorMessage={errors.addressLine2?.message}
              />
              <InputBox
                customClassName="border border-[#B2B2B2]   w-full rounded-md bg-[#F7F7F7]"
                customPlaceholder="city"
                register={{ ...register("city") }}
                errorMessage={errors.city?.message}
              />
              <InputBox
                customClassName="border border-[#B2B2B2]   w-full rounded-md bg-[#F7F7F7]"
                customPlaceholder="state"
                register={{ ...register("state") }}
                errorMessage={errors.state?.message}
              />
              <InputBox
                customClassName="border border-[#B2B2B2]   w-full rounded-md bg-[#F7F7F7]"
                customPlaceholder="ZIP code"
                register={{ ...register("zipcode") }}
                errorMessage={errors.zipcode?.message}
              />
              <InputBox
                customClassName="border border-[#B2B2B2]   w-full rounded-md bg-[#F7F7F7]"
                customPlaceholder="phone number"
                register={{ ...register("phonenumber") }}
                errorMessage={errors.phonenumber?.message}
              />
            </div>
          </div>
          <div className="flex gap-2 items-center">
            <input
              type="checkbox"
              className="border-2 border-primary ml-2 text-primary"
            />
            <p>save this information for next time</p>
          </div>
          <h1 className="text-primary text-[18px] font-[700]">
            Order Delivery
          </h1>
          {checkOutProducts &&
            checkOutProducts.map((item, index) => {
              return (
                <Cartitem
                  key={index}
                  data={item}
                  productsHandler={(product) =>
                    checkOutProductsHandler(product)
                  }
                ></Cartitem>
              );
            })}
          {checkOutProducts.length === 0 && (
            <p className="text-red-400">* Cart cannot be empty</p>
          )}
          <div className=" bg-[#F7F7F7] px-2 flex flex-col lg:items-center sm:my-2 sm:py-1">
            <div className="flex justify-between  lg:justify-normal lg:gap-8 my-4">
              <h1 className="text-[16px]  lg:text-[20px] sm:text-[15px] font-normal">
                Subtotal:
              </h1>
              <h1 className="text-[16px]  lg:text-[20px] sm:text-[15px] font-bold">
                {productsPrice} USD*
              </h1>
            </div>
            <div className="flex justify-between  lg:justify-normal lg:gap-8 mb-2">
              <h1 className="text-[16px]  lg:text-[20px] sm:text-[15px] font-normal">
                Shipping:
              </h1>
              <h1 className="text-[16px]  lg:text-[20px] sm:text-[15px] font-bold">
                {shippingPrice} USD*
              </h1>
            </div>
            <div className="border-b border-2 my-4"></div>
            <div className="flex justify-between  lg:justify-normal lg:gap-8 mb-4">
              <h1 className="text-[16px]  lg:text-[20px] sm:text-[15px] font-normal">
                Total
              </h1>
              <h1 className="text-[16px]  lg:text-[20px] sm:text-[15px] font-bold">
                {totalPrice} USD*
              </h1>
            </div>
          </div>
          <h1 className="text-primary   text-[18px] font-[700]">
            Delivery mode
          </h1>
          <div className="grid grid-cols-2 gap-4">
            <button
              className={`border h-[40px] ${
                deliveryOption === "Standard"
                  ? "border-primary bg-primary text-white"
                  : "border-primary text-primary"
              } rounded-md hover:bg-primary hover:text-white`}
              onClick={() => handleOptionSelect("Standard")}
            >
              Standard
            </button>
            <button
              className={`border h-[40px] ${
                deliveryOption === "Express"
                  ? "border-primary bg-primary text-white"
                  : "border-primary text-primary"
              } rounded-md hover:bg-primary hover:text-white`}
              onClick={() => handleOptionSelect("Express")}
            >
              Express
            </button>
          </div>

          <button
            type="submit"
            className="w-full h-[40px]  border border-primary  rounded-md  text-white bg-primary cursor-pointer"
            onClick={handlePayNowClick}
          >
            Pay Now
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CheckCart;
