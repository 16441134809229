import React, { createContext, useContext, useState } from "react";

const FaceMashContext = createContext();

export const useFaceMash = () => useContext(FaceMashContext);

export const FaceMashProvider = ({ children }) => {
  const [srcImg, setSrcImg] = useState("");
  const [targetImg, settargetImg] = useState("");
  const [facemashImg, setFacemashImg] = useState("");

  const setFaceMashImg = (facemash) => {
    setFacemashImg(facemash);
    localStorage.setItem("facemash", JSON.stringify(facemash));
  };

  const setTargetImg = (img) => {
    settargetImg(img);
    localStorage.setItem("targetImg", JSON.stringify(img));
  };

  const value = {
    srcImg,
    setSrcImg,
    targetImg,
    setTargetImg,
    facemashImg,
    setFaceMashImg,
  };
  return (
    <FaceMashContext.Provider value={value}>
      {children}
    </FaceMashContext.Provider>
  );
};
