import React, { useState, useEffect } from "react";
import { Categories } from "../components/common/Categories";
import { getAllFaceMash } from "../network/FacemashAPI";
import { useAuth } from "../context/AuthContext";
import { Navigate, useNavigate } from "react-router-dom";
import { useLoader } from "../context/LoaderContext";
import Navbar from "../components/layout/Navbar";
import BackButton from "../components/common/BackButton";
import Footer from "../components/layout/footer";

function UploadsNFaceMash() {
  const [imagesData, setImagesData] = useState([]);
  const { user } = useAuth();
  const navigate = useNavigate();
  const { loader, setLoader, Spinner } = useLoader();

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData === null) {
      navigate("/home");
    } else {
      setLoader(true);
      getAllFaceMash(userData)
        .then((res) => {
          const result = res.data.data;
          const imagesData = result.reduce((acc, item) => {
            if (item.source) {
              acc.push({ image_url: item.source, tag: "uploads" });
            }
            if (item.aws_url) {
              acc.push({ image_url: item.aws_url, tag: "facemash" });
            }
            return acc;
          }, []);
          setImagesData(imagesData.reverse());
          console.log("Images Data", imagesData);
          setLoader(false);
        })
        .catch((err) => {
          console.error("Error fetching FaceMash images:", err);
          setLoader(false);
        });
    }
  }, []);

  return loader === true ? (
    <Spinner></Spinner>
  ) : (
    <>
      <Navbar>
        <div className="flex-1">
          <BackButton />
          <p className="text-base text-black">back</p>
        </div>
      </Navbar>
      <div className="m-4">
        <Categories data={imagesData} categories={["uploads", "facemash"]} />
      </div>
      <Footer />
    </>
  );
}

export default UploadsNFaceMash;
