import React, { useState, useEffect } from "react";
import { useCart } from "../context/CartContext";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import CheckoutForm from "../components/common/CheckoutForm";
import axios from "axios";
import Navbar from "../components/layout/Navbar";
import BackButton from "../components/common/BackButton";
import Footer from "../components/layout/footer";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);

function PaymentForm() {
  const { paymentData, setPaymentData } = useCart();
  const [clientSecret, setClientSecret] = useState("");
  const navigate = useNavigate();
  const { user, login } = useAuth();

  useEffect(() => {
    const userLocalData = JSON.parse(localStorage.getItem("userData"));
    const paymentDataLocal = JSON.parse(localStorage.getItem("paymentData"));
    const dataToPassForPayment = {
      contactemail: paymentDataLocal.contactemail,
      email: paymentDataLocal.email,
      country: paymentDataLocal.country,
      firstname: paymentDataLocal.firstname,
      lastname: paymentDataLocal.lastname,
      addressLine1: paymentDataLocal.addressLine1,
      addressLine2: paymentDataLocal.addressLine2,
      city: paymentDataLocal.city,
      state: paymentDataLocal.state,
      zipcode: paymentDataLocal.zipcode,
      phonenumber: paymentDataLocal.phonenumber,
      deliveryOption: paymentDataLocal.deliveryOption,
      products: paymentDataLocal.products.map((product) => {
        return {
          id: product.id,
          product_id: product.product_id,
          quantity: product.quantity,
          size: product.size,
          price: product.price,
        };
      }),

      totalprice: 50,
    };
    console.log("Data to pass for payment", dataToPassForPayment);
    setPaymentData(paymentDataLocal);
    if (userLocalData) {
      login(userLocalData);
      // Create PaymentIntent as soon as the page loads
      const url = `${process.env.REACT_APP_BASE_URL}/v1/payment_intents`;
      const data = {
        amount: paymentDataLocal === null ? 0 : paymentDataLocal.totalprice,
        currency: "INR",
        description: dataToPassForPayment === null ? "" : dataToPassForPayment,
      };
      axios
        .post(url, data, {
          headers: {
            token: userLocalData.token,
          },
        })
        .then((response) => {
          setClientSecret(response.data.client_secret);
        })
        .catch((error) => {
          console.error("Error creating PaymentIntent:", error);
        });
    } else {
      navigate("/home");
    }
  }, []);

  console.log("payment Data", paymentData);

  return (
    <>
      <Navbar>
        <div className="flex-1">
          <BackButton />
          <p className="text-base text-black">payment</p>
        </div>
      </Navbar>
      <div>
        {clientSecret && (
          <Elements stripe={stripePromise}>
            <CheckoutForm clientSecret={clientSecret} />
          </Elements>
        )}
      </div>
      <Footer />
    </>
  );
}

export default PaymentForm;

// import React, { useState, useEffect } from "react";
// import { useCart } from "../context/CartContext";
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";
// import { useAuth } from "../context/AuthContext";
// import { useNavigate } from "react-router-dom";
// import CheckoutForm from "../components/common/CheckoutForm";
// import axios from "axios";

// const stripePromise = loadStripe(
//   "pk_test_51NcU5oFwnAdT8fEAeLMguyxCOIsSBSjkpRNqGUD46MRV7jpzujzs2hNTg6WFIuQX0mbuBW0zoJHNVdObj98qKBuH00JgZTFFPJ"
// );

// function PaymentForm() {
//   const { paymentData } = useCart();
//   const [clientSecret, setClientSecret] = useState("");
//   const navigate = useNavigate();
//   const { user, login } = useAuth();

//   useEffect(() => {
//     const userLocalData = JSON.parse(localStorage.getItem("userData"));
//     if (userLocalData) {
//       login(userLocalData);
//       // Create PaymentIntent as soon as the page loads
//       axios
//         .post("https://partly-grateful-flounder.ngrok-free.app/v1/payment_intents", {
//           amount: "888",
//           currency: "INR"
//         })
//         .then((response) => {
//           setClientSecret(response.data.client_secret);
//         })
//         .catch((error) => {
//           console.error("Error creating PaymentIntent:", error);
//         });
//     } else {
//       navigate("/home");
//     }
//   }, []); // Empty dependency array to run only once on component mount

//   const confirmPaymentIntent = async () => {
//     try {
//       const response = await axios.post(`https://partly-grateful-flounder.ngrok-free.app/v1/payment_intents/${clientSecret}/confirm`);
//       // Handle the response as needed, for example:
//       console.log("Payment intent confirmed:", response.data);
//     } catch (error) {
//       console.error("Error confirming PaymentIntent:", error);
//     }
//   };

//   const appearance = {
//     theme: "stripe",
//   };
//   const options = {
//     clientSecret,
//     appearance,
//   };

//   return (
//     <div>
//       {clientSecret && (
//         <Elements options={options} stripe={stripePromise}>
//           <CheckoutForm confirmPaymentIntent={confirmPaymentIntent} />
//         </Elements>
//       )}
//     </div>
//   );
// }

// export default PaymentForm;
