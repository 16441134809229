import React, { useEffect } from "react";
import UploadImg from "./../components/common/UploadImg";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/layout/Navbar";
import BackButton from "../components/common/BackButton";
import Footer from "../components/layout/footer";

const UploadImage = () => {
  const { user, setIsLoginPopUpOpen } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData === null) {
      setIsLoginPopUpOpen(true);
      navigate("/home");
    }
  }, []);
  return (
    <>
      <Navbar>
        <div className="flex-1 items-center gap-2 px-1" id="up-text">
          <div className="text-md md:text-2xl text-pk mt-1 md:mt-2">
            <BackButton />
          </div>
          <h1 className="text-black text-base md:text-2xl ">upload image</h1>
        </div>
      </Navbar>
      <div className="m-4 flex flex-col ">
        <UploadImg />
        <div className="flex flex-col px-3 py-2 mb-5">
          <p className="text-left md:text-xl" id="txt">
            for best results, we recommend images that are closeup and without
            elements like sun glasses, hats, etc, enjoy!
          </p>
          <div className="grid grid-cols-2 md:grid-cols-4 space-x-4 md:space-x-5 mt-6 p-4 ml-0">
            <img
              src="/images/female.png"
              alt="img2"
              className="object-fit h-44 w-40 md:h-[200px] md:w-[200px] xl:h-[250px] xl:w-[250px] dxl:h-[300px] dxl:w-[300px] rounded-sm"
            />
            <img
              src="/images/male.png"
              alt="img1"
              className=" object-fit h-44 w-40 md:h-[200px] md:w-[200px] xl:h-[250px] xl:w-[250px] dxl:h-[300px] dxl:w-[300px] rounded-sm"
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default UploadImage;
