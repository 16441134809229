import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;

async function uploadToFaceMash(user, targetFileInput, srcImage) {
  const formData = new FormData();
  formData.append("target", targetFileInput);
  formData.append("source", srcImage);
  formData.append("output", "content/swapped.jpeg");
  formData.append("execution_provider", "cpu");
  const myHeaders = {
    token: user.token,
  };
  const url = `${baseUrl}/swapImages`;
  return axios.post(url, formData, { headers: myHeaders });
}

function getFaceMashById(user) {
  const myHeaders = {
    token: user.token,
  };
  const url = `${baseUrl}/getImage`;
  return axios
    .get(url, {
      headers: myHeaders,
      responseType: "blob", // Tell Axios to expect binary data
    })
    .then((response) => {
      console.log("Response from getFaceMashById:", response);
      return response.data; // This will be binary data
    })
    .catch((error) => {
      console.error("Error in getFaceMashById:", error);
      throw error;
    });
}

function getAllFaceMash(user) {
  const myHeaders = {
    token: user.token,
  };
  const url = `${baseUrl}/facemash/list`;
  return axios.get(url, { headers: myHeaders });
}

export { uploadToFaceMash, getFaceMashById, getAllFaceMash };
