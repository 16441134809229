import axios from "axios";
const baseUrl = process.env.REACT_APP_BASE_URL;

function getAvatarsCollections() {
  const url = `${baseUrl}/category/list`;
  return axios.get(url);
}

function getGalleryByCategory(user) {
  const myHeaders = {
    token: user.token,
  };
  const url = `${baseUrl}/gallery/list`;
  return axios(url, { headers: myHeaders });
}

export { getAvatarsCollections, getGalleryByCategory };
