import { useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useSwipeable } from "react-swipeable"; // Import useSwipeable hook

export default function Carousel({
  images = [],
  imageStyles = "object-cover",
}) {
  const [activeIndex, setActiveIndex] = useState(0);

  const handlers = useSwipeable({
    onSwipedLeft: () => goToNext(),
    onSwipedRight: () => goToPrev(),
  });

  const goToPrev = () => {
    setActiveIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const goToNext = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const goToImage = (index) => {
    setActiveIndex(index);
  };

  return (
    <div {...handlers} className="flex flex-col carousel w-full">
      {images.map((image, index) => (
        <div
          key={index}
          className={`carousel-item relative w-full ${
            activeIndex === index ? "block" : "hidden"
          }`}
        >
          <img
            src={image.image_url}
            className={"w-full h-full rounded-sm object-cover  " + imageStyles}
            alt={image.objectName}
          />
        </div>
      ))}
      <div className="my-2 flex justify-center">
        {images.map((_, index) => (
          <button
            key={index}
            onClick={() => goToImage(index)}
            className={`h-3 w-3 mx-1 rounded-full ${
              activeIndex === index ? "bg-primary" : "bg-gray-300"
            }`}
          />
        ))}
      </div>
    </div>
  );
}
