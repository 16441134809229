import React from "react";

function Dropdown({ title, options, register, errorMessage }) {
  return (
    <>
      <select
        className="select w-full text-[#94a3b8] border border-[#B2B2B2] w-full rounded-md bg-[#F7F7F7]"
        defaultValue=""
        {...register}
      >
        <option disabled className="text-[#94a3b8]" value="">
          {title}
        </option>
        {options.map((option, index) => (
          <option key={index}>{option}</option>
        ))}
      </select>
      <span className="text-red-400">{errorMessage}</span>
    </>
  );
}

export default Dropdown;
