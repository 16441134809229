// PaymentSuccessModal.js
import React from "react";
import Modal from "react-modal";
import Button from "../common/button";
import { Link } from "react-router-dom";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%", // Half of the screen width
    height: "25%", // Half of the screen height
    borderRadius: "8px", // Optional: Adds border radius
    boxShadow: "0 4px 8px rgba(0,0,0,0.2)", // Optional: Adds shadow
  },
};

const PaymentSuccessModal = ({ isOpen, closeModal }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Payment Success"
      style={customStyles}
    >
      <div className="flex flex-col items-center justify-center gap-2">
        <h2 className="text-green-500 text-center">Payment Successful !</h2>
        <p className="text-center">Thank you for your payment.</p>
        <div className="flex flex-row gap-2">
          <Link
            to="/myuploads"
            className="border border-black border-1  p-2 rounded-md"
          >
            uploads
          </Link>
          <button
            onClick={closeModal}
            className="border border-black border-1  p-2 rounded-md text-red-800"
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default PaymentSuccessModal;
