import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useFaceMash } from "../../context/FaceMashContext";
import { useCart } from "../../context/CartContext";

const MerchCard = ({ data }) => {
  const targetPath = "/merchimage";
  const { facemashImg } = useFaceMash();
  const { finalProductImage, setFinalProductImage } = useCart();
  const mergedImage = data.mergedImage;

  return (
    <div className="col-span-6 md:col-span-4 relative">
      <Link
        to={`${targetPath}/${data._id}`}
        onClick={() => setFinalProductImage(mergedImage)}
      >
        <img
          src={mergedImage}
          alt=""
          className="rounded-sm object-cover m-auto object-scale-down"
        />
        {/* <span className="text-pk text-3xl font-bold">{data.name}</span> */}
      </Link>
      <div className="flex justify-between">
        <h1 className="font-Raleway text-primary text-[18px] font-[500 ]">
          {data && data.name && data.name.toLowerCase()}
        </h1>
        <h1 className=" text-[15px] font-sans font-bold">{data.price} USD </h1>
      </div>
      <div>
        <p className=" text-[10px] font-sans md:text-[16px] sm:text-[12px] ">
          {data.description}
        </p>
      </div>
    </div>
  );
};

export default MerchCard;
