// App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";

import Navbar from "./components/layout/Navbar";
import { SigninPage } from "./pages/SigninPage";

import ProductDetail2 from "./components/common/ProductDetail2";
import ProductDetail from "./components/common/ProductDetail";
import UploadImage from "./pages/UploadImage";
import MerchPage from "./pages/MerchPage";
import Cart from "./components/common/Cart";
import FacemashCollection from "./pages/FacemashCollection";
import Footer from "./components/layout/footer";
import CheckCart from "./components/common/CheckCart";
import UploadsNFaceMash from "./pages/UploadsNFaceMash";
import PaymentForm from "./pages/PaymentForm";
import PaymentSuccess from "./components/common/PaymentSuccess";
import PrivacyPolicy from "./pages/PrivacyPolicy";

function App() {
  return (
    <>
      <div>
        <Routes>
          {/* <Route path="/" element={<SigninPage />} /> */}
          <Route path="/" element={<HomePage />} />

          <Route path="/home" element={<HomePage />} />
          <Route path="/merch" element={<MerchPage />} />
          <Route
            path="/merchimage/:id/:color?/:size"
            element={<ProductDetail2 />}
          />
          <Route path="/merchimage/:id" element={<ProductDetail />} />
          {/* <Route path="/merchimage2/:id" element={<ProductDetail2 />} /> */}
          <Route
            path="/facemash-collection/:title"
            element={<FacemashCollection />}
          />
          <Route path="/cart" element={<Cart />} />
          <Route path="/checkcart" element={<CheckCart />} />
          <Route path="/myuploads" element={<UploadsNFaceMash />} />
          <Route path="/upload" element={<UploadImage />} />
          <Route path="/payment" element={<PaymentForm />} />
          <Route path="/payment-success" element={<PaymentSuccess />} />
          {/* <Route path="/facemash-collection" element={<FacemashCollection />} /> */}
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        </Routes>
      </div>
      {/* <Footer /> */}
    </>
  );
}

export default App;
