import React, { useEffect, useState, useRef } from "react";
import Carousal from "../components/common/Carousal";
import { Categories } from "../components/common/Categories";
import SigninPage from "./SigninPage.js";
import { useAuth } from "../context/AuthContext.js";
import { useLoader } from "../context/LoaderContext.js";
import { getBannerImgs } from "../network/BannerImgs.js";
import { getAvatarsCollections } from "../network/CollectionsAPI.js";
import Navbar from "../components/layout/Navbar.js";
import Logo from "../components/common/Logo.js";
import { firestore } from "../firebase_setup/firebase.js";
import Footer from "../components/layout/footer.js";

function HomePage() {
  const { user, login, LoginPopUp, isLoginPopUpOpen, setIsLoginPopUpOpen } =
    useAuth();

  const { loader, setLoader, Spinner } = useLoader();

  const [bannerImgs, setBannerImgs] = useState([]);
  const [avatarCollection, setAvatarsCollection] = useState([]);
  const popupRef = useRef(null);
  // console.log(firestore);
  useEffect(() => {
    setLoader(true);
    const fetchBannerImages = async () => {
      return await getBannerImgs();
    };
    const fetchAvatarCollection = async () => {
      return await getAvatarsCollections();
    };
    fetchBannerImages()
      .then((response) => {
        const { data } = response;
        setBannerImgs(data.data);
        setLoader(false);
      })
      .catch((error) => {
        console.log("Error fetching banner images:", error);
        setLoader(false);
      });
    fetchAvatarCollection()
      .then((response) => {
        const { data } = response;
        setAvatarsCollection(data.data);
        setLoader(false);
      })
      .catch((error) => {
        console.log("Error fetching avatar collection:", error);
        setLoader(false);
      });

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Cleanup event listener on component unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  // console.log(isLoginPopUpOpen);
  // console.log("Banner Images:", bannerImgs);
  // console.log("Avatar Collection:", avatarCollection);

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      // Click occurred outside the popup
      setIsLoginPopUpOpen(false);
    }
  };

  return loader === true ? (
    <Spinner />
  ) : (
    <div>
      <Navbar className="w-max">
        <div className="flex-1">
          <Logo taglineFontStyle="text-black" className="cursor-pointer" />
        </div>
      </Navbar>
      <div className="relative">
        <div className="w-screen ">
          <Carousal images={bannerImgs} />
        </div>
        <div className="m-4 mb-4">
          <div
            className="flex flex-col justify-center gap-2 items-center w-full mx-auto mt-5 px-4"
            id="content"
          >
            <h3
              className="main-text text-pk text-xl m-2 font-raleway font-bold"
              style={{ fontSize: "22px" }}
            >
              welcome to facemash!
            </h3>
            <p className="text-center text-sm">
              Create stunning AI-powered images of yourself, your loved ones or
              your favourite musician or actress, and get these printed on
              Water-bottles, T-shirts and more!
            </p>
          </div>
          <div className=" flex justify-center items-center m-4">
            {user === null && <SigninPage />}
          </div>
          <h2 className="text-2xl text-primary  font-[400]  font-raleway">
            avatar collection
          </h2>
          <Categories
            data={avatarCollection}
            title={"avatar collection"}
            categories={["all", "male", "female"]}
            route={"/facemash-collection"}
          />
        </div>
        <Footer />
        {isLoginPopUpOpen && (
          <div className="fixed inset-0 flex items-center justify-center">
            <div className="absolute h-lvh w-screen bg-black opacity-70"></div>
            <div
              ref={popupRef}
              className="absolute bg-white p-6 rounded-lg shadow-lg"
            >
              <LoginPopUp />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default HomePage;
