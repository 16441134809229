import axios from "axios";
const baseUrl = process.env.REACT_APP_BASE_URL;

function getProducts(user) {
  const myHeaders = {
    token: user.token,
  };
  const url = `${baseUrl}/products/list`;
  return axios.get(url, { headers: myHeaders });
}

function getProductById(user, id) {
  const myHeaders = {
    token: user.token,
  };
  const url = `${baseUrl}/product/${id}`;
  return axios.get(url, { headers: myHeaders });
}

function addProduct(user, name, size, type, description, imageUrl) {
  const data = new FormData();
  data.append("name", name);
  data.append("size", size);
  data.append("type", type);
  data.append("description", description);
  data.append("imageUrl", imageUrl);
  const myHeaders = {
    token: user.token,
  };
  const url = `${baseUrl}/addProduct`;
  return axios.post(url, data, { headers: myHeaders });
}

export { getProducts, getProductById, addProduct };
