import React from "react";

const InputBox = ({
  customClassName,
  customPlaceholder,
  register,
  errorMessage,
  onChange,
  value,
}) => {
  return (
    <div>
      <input
        className={`h-[45px] w-full ${customClassName} pl-3 `}
        placeholder={customPlaceholder}
        onChange={onChange}
        value={value}
        {...register}
      />
      <span className="text-red-400">{errorMessage}</span>
    </div>
  );
};

export default InputBox;
