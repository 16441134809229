import axios from "axios";
const baseUrl = `${process.env.REACT_APP_BASE_URL}/cart`;

const addCart = (user, productData) => {
  const token = user.token;
  const url = `${baseUrl}/add`;
  const myHeaders = {
    token: token,
  };
  return axios.post(url, productData, { headers: myHeaders });
};

const removeCart = (user, productData) => {
  const token = user.token;
  const { id } = productData;
  const url = `${baseUrl}/${id}`;
  const myHeaders = {
    token: token,
  };
  return axios.delete(url, { headers: myHeaders });
};

const updateQuantity = (user, productData) => {
  const token = user.token;
  const { id, quantity } = productData;
  const url = `${baseUrl}/${id}`;
  const myHeaders = {
    token: token,
  };
  return axios.put(url, { quantity: quantity }, { headers: myHeaders });
};

const getCart = (user) => {
  const token = user.token;
  const url = `${baseUrl}/list`;
  const myHeaders = {
    token: token,
  };
  return axios.get(url, { headers: myHeaders });
};

export { addCart, removeCart, updateQuantity, getCart };
