import React, { useState } from "react";
import SigninPage from "../../pages/SigninPage";

function LoginPopUp() {
  const [isOpen, setOpen] = useState(false);
  return (
    <div className="flex flex-col bg-white h-72 w-64 sm:w-80 p-4 gap-2 items-center justify-center">
      <h1 className="text-primary font-Raleway text-xl">login</h1>
      <p className="text-black text-lg font-sans text-center">
        create your own face mash, start creating & selling your brand to your
        audience. Customizing to your heart's desire! What is possible is
        infinite!
      </p>
      <SigninPage />
    </div>
  );
}

export default LoginPopUp;
