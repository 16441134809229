import React from "react";

function Button({ onClick, children, styles = "w-full" }) {
  return (
    <button
      onClick={onClick}
      className={
        "bg-[#ab1fbc] max-w-48 h-10 p-6 text-white rounded-md flex justify-center items-center " +
        styles
      }
    >
      {children}
    </button>
  );
}

export default Button;
