import axios from "axios";
const baseUrl = process.env.REACT_APP_BASE_URL;

function loginAPI(name, email, image) {
  const data = new FormData();
  data.append("name", name);
  data.append("email", email);
  data.append("image", image);
  const url = `${baseUrl}/googleLogin`;
  return axios.post(url, data);
}

export { loginAPI };
