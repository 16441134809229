import MerchCard from "../components/common/MerchCard";
import React, { useEffect, useState, useMemo } from "react";
import { useAuth } from "../context/AuthContext";
import { useLoader } from "../context/LoaderContext";
import { useNavigate } from "react-router-dom";
import { getProducts } from "../network/ProductsAPI";
import { useFaceMash } from "../context/FaceMashContext";
import { IoIosShareAlt } from "react-icons/io";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  PinterestIcon,
  RedditIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import BackButton from "../components/common/BackButton";
import Navbar from "../components/layout/Navbar";
import mergeImages from "merge-images";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";
import Footer from "../components/layout/footer";

const MerchPage = () => {
  const { user, setIsLoginPopUpOpen } = useAuth();
  const { loader, setLoader, Spinner } = useLoader();
  const navigate = useNavigate();

  const { facemashImg, setFaceMashImg } = useFaceMash();
  const { width, height } = useWindowSize();

  const [products, setProducts] = useState([]);
  const [mergedProducts, setMergedProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("Bottles");
  const [showConfetti, setShowConfetti] = useState(false);

  const imagesMergeHelper = async (data, productUrl, facemashUrl) => {
    // Function to resize image
    // setMergedImage(null);
    const resizeImage = (image, maxWidth, maxHeight, circular = false) => {
      const aspectRatio = image.width / image.height;
      let newWidth = maxWidth;
      let newHeight = maxHeight;

      if (aspectRatio > 1) {
        newHeight = Math.min(image.height, maxHeight);
        newWidth = newHeight * aspectRatio;
      } else {
        newWidth = Math.min(image.width, maxWidth);
        newHeight = newWidth / aspectRatio;
      }

      const canvas = document.createElement("canvas");
      canvas.width = newWidth;
      canvas.height = newHeight;
      const ctx = canvas.getContext("2d");

      if (circular) {
        // Create circular mask
        ctx.beginPath();
        ctx.arc(
          newWidth / 2,
          newHeight / 2,
          Math.min(newWidth, newHeight) / 2,
          0,
          Math.PI * 2
        );
        ctx.closePath();
        ctx.clip();
      }

      ctx.drawImage(image, 0, 0, newWidth, newHeight);

      return canvas.toDataURL();
    };

    const fetchImageAsBlob = async (imageUrl) => {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      return blob;
    };

    const productBlob = await fetchImageAsBlob(productUrl);
    const facemashBlob = await fetchImageAsBlob(facemashUrl);

    const productImage = new Image();
    const facemashImage = new Image();

    productImage.src = URL.createObjectURL(productBlob);
    facemashImage.src = URL.createObjectURL(facemashBlob);

    await Promise.all([
      new Promise((resolve) => {
        productImage.onload = resolve;
      }),
      new Promise((resolve) => {
        facemashImage.onload = resolve;
      }),
    ]);

    // Resize product image and facemash image
    let heightFacemashImg, widthFacemashImg;
    if (data.type === "Bottles") {
      heightFacemashImg = 35;
      widthFacemashImg = 35;
    } else if (data.type === "Clothing") {
      heightFacemashImg = 70;
      widthFacemashImg = 70;
    }
    const resizedProductUrl = resizeImage(productImage, 200, 200);
    const resizedFacemashUrl = resizeImage(
      facemashImage,
      heightFacemashImg,
      widthFacemashImg,
      true
    ); // Make facemash image circular

    let x, y;
    if (data.type === "Bottles") {
      x = 80;
      y = 80;
    } else if (data.type === "Clothing") {
      x = 65;
      y = 45;
    }
    return mergeImages([
      { src: resizedProductUrl },
      { src: resizedFacemashUrl, x: x, y: y },
    ]);
  };

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData === null) {
      setIsLoginPopUpOpen(true);
      navigate("/home");
    } else {
      setLoader(true);
      const facemashFromLocal = JSON.parse(localStorage.getItem("facemash"));
      setFaceMashImg(facemashFromLocal);
      const getAllProducts = async (user) => await getProducts(user);
      getAllProducts(userData)
        .then((res) => {
          console.log(res.data.data);
          setProducts(res.data.data);
          setLoader(false);
          confettiSetter();
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
          confettiSetter();
        });
    }
  }, []);

  const generateImageMergedProducts = async () => {
    if (products.length > 0) {
      const promises = products.map((item) =>
        item.ImageUrl && item.ImageUrl.length > 0
          ? imagesMergeHelper(item, item.ImageUrl[0], facemashImg).then(
              (b64) => ({
                ...item,
                mergedImage: b64,
              })
            )
          : null
      );
      return Promise.all(promises);
    }
    return [];
  };

  useEffect(() => {
    setLoader(true);
    generateImageMergedProducts()
      .then((mergedImages) => {
        setMergedProducts(mergedImages);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.error("Error merging images:", error);
      });
  }, [products]);

  const confettiSetter = () => {
    setShowConfetti(true);
    setTimeout(() => {
      setShowConfetti(false);
    }, 5000);
  };

  console.log("mergedProducts: ", mergedProducts);

  const filteredProducts = mergedProducts.filter(
    (item) => item.type === selectedCategory
  );

  return loader === true ? (
    <Spinner />
  ) : (
    <>
      <Navbar>
        <div className="flex-1">
          <BackButton />
          <p className="text-base text-black">facemash</p>
        </div>
      </Navbar>
      <div className="flex flex-col gap-3 m-4">
        {showConfetti && (
          <Confetti width={width} height={height} tweenDuration={5000} />
        )}
        <div className="flex flex-col text-center items-center">
          <h2 className="self-start text-pk text-2xl pb-[5px] font-bold m-auto">
            your facemash is ready !
          </h2>
          <div className="relative">
            <img
              className="h-[360px] object-cover border-[1px] border-primary rounded-sm m-2"
              src={facemashImg}
              alt=""
            />
            <IoIosShareAlt
              onClick={() => document.getElementById("my_modal_5").showModal()}
              className="absolute bg-primary text-3xl text-white bottom-4 right-4 p-1 rounded-full"
            />
            <dialog
              id="my_modal_5"
              className="modal modal-bottom md:modal-middle"
            >
              <div className="modal-box">
                <h3 className="font-bold text-lg">Share your facemash using</h3>
                <div className="flex flex-wrap">
                  <WhatsappShareButton
                    url={facemashImg}
                    className="m-1 cursor-pointer"
                  >
                    <WhatsappIcon size={40} round={true} />
                  </WhatsappShareButton>
                  <TwitterShareButton
                    url={facemashImg}
                    className="m-1 cursor-pointer"
                  >
                    <TwitterIcon size={40} round={true} />
                  </TwitterShareButton>
                  <EmailShareButton
                    url={facemashImg}
                    className="m-1 cursor-pointer"
                  >
                    <EmailIcon size={40} round={true} />
                  </EmailShareButton>
                  <FacebookShareButton
                    url={facemashImg}
                    className="m-1 cursor-pointer"
                  >
                    <FacebookIcon size={40} round={true} />
                  </FacebookShareButton>
                  <LinkedinShareButton
                    url={facemashImg}
                    className="m-1 cursor-pointer"
                  >
                    <LinkedinIcon size={40} round={true} />
                  </LinkedinShareButton>
                  {/* <PinterestShareButton
                    url={facemashImg}
                    className="m-1 cursor-pointer"
                  >
                    <PinterestIcon size={40} round={true} />
                  </PinterestShareButton> */}
                  <RedditShareButton
                    url={facemashImg}
                    className="m-1 cursor-pointer"
                  >
                    <RedditIcon size={40} round={true} />
                  </RedditShareButton>
                  <TelegramShareButton
                    url={facemashImg}
                    className="m-1 cursor-pointer"
                  >
                    <TelegramIcon size={40} round={true} />
                  </TelegramShareButton>
                  {/* Add other share buttons here */}
                </div>
                <div className="modal-action">
                  <form method="dialog">
                    <button className="btn">Close</button>
                  </form>
                </div>
              </div>
            </dialog>
          </div>
          <h3 className="pt-4 text-[14px] sm:W-[352PX] SM:h-[44px] sm:text-[16px] md:text-[16px] lg:text-[28px]">
            your facemash is now available as beautifully printed premium water
            bottles and T-shirts !
          </h3>
        </div>

        <div className="flex flex-col gap-2">
          <div className="flex gap-4 ">
            <button
              className={`${
                selectedCategory === "Bottles"
                  ? "border-b-2 border-primary font-Raleway text-16 rounded-sm text-primary"
                  : ""
              }`}
              onClick={() => setSelectedCategory("Bottles")}
            >
              Water Bottles
            </button>
            <button
              className={`${
                selectedCategory === "Clothing"
                  ? "border-b-2 border-primary font-Raleway text-16 rounded-sm text-primary"
                  : ""
              }`}
              onClick={() => setSelectedCategory("Clothing")}
            >
              T-Shirts
            </button>
            <button
              className={`${
                selectedCategory === "Coins"
                  ? "border-b-2 border-primary font-Raleway text-16 rounded-sm text-primary"
                  : ""
              }`}
              onClick={() => setSelectedCategory("Coins")}
            >
              Coins
            </button>
          </div>
          <div className="grid grid-cols-12 gap-4">
            {filteredProducts.map((item, index) => (
              <MerchCard key={index} data={item} />
            ))}
          </div>
          {filteredProducts.length === 0 && (
            <span className="m-auto my-4">
              As of now, no products in this category
            </span>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MerchPage;
