import React from "react";
import ReactDOM from "react-dom/client";
import "./css/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, HashRouter } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import { LoaderProvider } from "./context/LoaderContext";
import { FaceMashProvider } from "./context/FaceMashContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ScrollToTop from "./components/common/ScrollToTop";
import { CartProvider } from "./context/CartContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId="915797480988-35lf98stu5tj2uucrpn4kh0cbl50o208.apps.googleusercontent.com">
      <AuthProvider>
        <LoaderProvider>
          <FaceMashProvider>
            <CartProvider>
              {/* <BrowserRouter> */}
              <HashRouter>
                <ScrollToTop />
                <App />
                {/* </BrowserRouter> */}
              </HashRouter>
            </CartProvider>
          </FaceMashProvider>
        </LoaderProvider>
      </AuthProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
