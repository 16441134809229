import React, { useEffect } from "react";
import BackButton from "./BackButton";
import Cartitem from "./Cartitem";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { useCart } from "../../context/CartContext";
import { getCart, removeCart, updateQuantity } from "../../network/CartAPI";
import Navbar from "../layout/Navbar";
import Footer from "../layout/footer";

const Cart = () => {
  const { user, setIsLoginPopUpOpen } = useAuth();
  const navigate = useNavigate();
  const { cart, setCart, setCartSize, setCheckOutProducts } = useCart();
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData === null) {
      setIsLoginPopUpOpen(true);
      navigate("/home");
    } else {
      getCart(userData)
        .then((res) => {
          console.log(res);
          console.log(res.data.data[0].products);
          if (res.data.data[0].products) setCart(res.data.data[0].products);
          else setCart([]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const cartProductsHandler = (productData) => {
    if (cart.length > 0) {
      console.log(productData);
      if (productData.quantity > 0) {
        const updatedCartProducts = cart.map((product) => {
          if (product && productData && product.id === productData.id) {
            return {
              ...product,
              quantity: productData.quantity,
            };
          } else return product;
        });
        setCart(updatedCartProducts);
        console.log("Updated cart products", updatedCartProducts);
        // localStorage.setItem("cart", JSON.stringify(updatedCartProducts));
        updateQuantity(user, productData)
          .then((res) => console.log(res))
          .catch((err) => console.log(err));
      } else {
        removeCart(user, productData)
          .then((res) => {
            console.log(res);
            setCartSize((prev) => prev - 1);
            const updatedCartProducts = cart.filter(
              (product) => product.id !== productData.id
            );
            setCart(updatedCartProducts);
            localStorage.setItem("cart", JSON.stringify(updatedCartProducts));
            console.log("updatedCart", updatedCartProducts);
          })
          .catch((err) => console.log(err));
      }
    }
  };
  return (
    <div>
      <Navbar>
        <div className="flex-1">
          <BackButton />
          <p className="text-base text-black">cart</p>
        </div>
      </Navbar>
      <div className="flex flex-col m-4">
        {cart.length === 0 ? (
          <div className="text-center text-[#595959] text-[20px] font-normal">
            Your cart is empty
          </div>
        ) : (
          cart.map((item, index) => {
            return (
              <Cartitem
                key={index}
                data={item}
                productsHandler={(product) => cartProductsHandler(product)}
              ></Cartitem>
            );
          })
        )}

        <div className=" bg-[#F7F7F7] px-2 flex flex-col lg:items-center sm:my-2 sm:py-1">
          <div className="flex justify-around items-center  lg:justify-normal lg:gap-8 my-8">
            <div className="flex ite gap-2">
              <h1 className="text-[18px]  lg:text-[20px] sm:text-[15px] font-bold">
                Total:
              </h1>
              <h1 className="text-[18px]  lg:text-[20px] sm:text-[15px] font-bold">
                {cart.reduce(
                  (acc, item) => acc + item.price * item.quantity,
                  0
                )}
              </h1>
            </div>
            <button
              onClick={() => {
                setCheckOutProducts(cart);
                if (cart.length > 0) navigate("/checkcart");
              }}
              className=" flex-none w-[200px] h-[40px] sm:w-[100px] sm:h-[40px] border border-primary text-white rounded-md bg-primary "
            >
              Check out
            </button>
          </div>
          <div>
            <p className="text-center text-[#595959] sm:text-[15px]">
              price excludes shipping and taxes which will be calculated on
              checkout.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Cart;
