// PaymentFailureModal.js
import React from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";

Modal.setAppElement("#root");
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%", // Half of the screen width
    height: "25%", // Half of the screen height
    borderRadius: "8px", // Optional: Adds border radius
    boxShadow: "0 4px 8px rgba(0,0,0,0.2)", // Optional: Adds shadow
  },
};
const PaymentFailureModal = ({ message, isOpen, closeModal }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Payment Failure"
      style={customStyles}
    >
      <div className="flex flex-col items-center justify-center gap-2">
        <h2 className="text-red-500 text-center text-xl">Payment Failure !</h2>
        <p className="text-red-400 tex-center text-lg">{message}</p>
        <div className="flex flex-row gap-2">
          <Link
            to="/payment"
            className="border border-black border-1  p-2 rounded-md"
          >
            try again
          </Link>
          <button
            onClick={closeModal}
            className="border border-black border-1  p-2 rounded-md text-red-800"
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default PaymentFailureModal;
