import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;

function getFavourites(user) {
  const myHeaders = {
    token: user.token,
  };
  const url = `${baseUrl}/favourites/list`;
  return axios.get(url, { headers: myHeaders });
}

function addFavourite(user, image_id) {
  const myHeaders = {
    token: user.token,
  };
  const url = `${baseUrl}/favourites/${image_id}/bookmark`;
  return axios.post(url, {}, { headers: myHeaders });
}

function removeFavourite(user, image_id) {
  const myHeaders = {
    token: user.token,
  };
  const url = `${baseUrl}/favourites/${image_id}/remove`;
  return axios.post(url, {}, { headers: myHeaders });
}

function isFavorite(user, image_id) {
  const myHeaders = {
    token: user.token,
  };
  const url = `${baseUrl}/favourites/status/${image_id}`;
  return axios.get(url, { headers: myHeaders });
}

export { getFavourites, addFavourite, removeFavourite, isFavorite };
