import React, { useState, useEffect } from "react";
import { MdFavorite } from "react-icons/md";
import { MdFavoriteBorder } from "react-icons/md";
import { useAuth } from "../../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import {
  addFavourite,
  isFavorite,
  removeFavourite,
} from "../../network/FavouritesAPI";
import { useFaceMash } from "../../context/FaceMashContext";

function Card({
  src,
  title,
  image_id = 1234,
  add = false,
  route = "",
  showFavIcon = false,
}) {
  const { user } = useAuth();
  const { targetImg, setTargetImg } = useFaceMash();
  const [favorite, setFavorite] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    if (user !== null) {
      const checkFavoriteStatus = async (user, image_id) =>
        await isFavorite(user, image_id);
      checkFavoriteStatus(user, image_id)
        .then((res) => {
          setFavorite(res.data.data);
        })
        .catch((err) => console.log(err));
    }
  }, [user, image_id]);

  const addToFavourite = async () => {
    setFavorite(true);
    return await addFavourite(user, image_id)
      .then((res) => console.log(res))
      .catch((err) => {
        console.log(err);
        setFavorite(false);
      });
  };
  const removeFromFavourite = async () => {
    setFavorite(false);
    return await removeFavourite(user, image_id)
      .then((res) => console.log(res))
      .catch((err) => {
        console.log(err);
        setFavorite(true);
      });
  };

  const cardClickhandler = () => {
    if (route !== "") {
      navigate(`${route}/${title}`);
    }
  };
  const cardPointerStyle = route !== "" ? " cursor-pointer" : "";
  return (
    <div
      className={
        "md:col-sap-4 col-span-6 flex flex-col items-center gap-1" +
        cardPointerStyle
      }
      onClick={cardClickhandler}
    >
      <div className="relative">
        <img
          className="h-44 w-44 object-cover border border-4 border-[#C8C8C8] rounded-md"
          src={src}
        />
        {showFavIcon &&
          user !== null &&
          (favorite === false || favorite === null ? (
            <MdFavoriteBorder
              className="absolute top-2 left-2 text-2xl text-pk bg-white rounded-full p-1 cursor-pointer"
              onClick={() => addToFavourite()}
            />
          ) : (
            <MdFavorite
              className="absolute top-2 left-2 text-2xl text-pk bg-white rounded-full p-1 cursor-pointer"
              onClick={() => removeFromFavourite()}
            />
          ))}

        {add === true && user !== null && (
          <Link to={{ pathname: "/upload" }} onClick={() => setTargetImg(src)}>
            <img
              src="/images/icons/addIcon.svg"
              className="absolute -bottom-2 -right-2"
            />
          </Link>
        )}
      </div>
      {/* {route !== "" ? (
        <Link to={`${route}/${title}`}>
          <p className="text-pk font-raleway">{title}</p>
        </Link>
      ) : (
        )} */}
      <p className="text-pk font-raleway">{title}</p>
    </div>
  );
}

export default Card;
