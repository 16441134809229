import React, { useState } from "react";
import Cards from "./Cards.js";
import { useAuth } from "../../context/AuthContext.js";
import { getFavourites } from "../../network/FavouritesAPI.js";

export const Categories = ({
  title,
  data = [],
  categories = [],
  route,
  message = "",
  add = false,
  showFavIcon = false,
}) => {
  const [category, setCategory] = useState(categories[0]);
  const [favoriteItems, setFavouriteItems] = useState([]);
  const { user } = useAuth();

  const getFavouritesByCategory = (user, category) => {
    const getFavouritesData = async (user) => await getFavourites(user);
    getFavouritesData(user)
      .then((res) => {
        console.log(res.data.data[0].gallery);
        setFavouriteItems(
          data.filter((item) => res.data.data[0].gallery.includes(item._id))
        );
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="flex flex-col gap-4 ">
      {/* <h2 className="text-2xl text-primary  font-[400]  font-raleway">
        {title}
      </h2> */}
      <div className=" flex flex-col gap-4 ">
        <div className="flex gap-4 items-center">
          {categories.map((item, index) => {
            return (
              <div className="flex flex-col gap-1" key={`${item}-${index}`}>
                <p
                  onClick={() => {
                    setCategory(item);
                    if (item === "your favorites") {
                      getFavouritesByCategory(user, title);
                    }
                  }}
                  className={
                    category === item
                      ? "!text-pk font-raleway cursor-pointer"
                      : "text-black font-raleway cursor-pointer"
                  }
                >
                  {item}
                </p>
                {category === item && <hr className="border-pk border-1"></hr>}
              </div>
            );
          })}
        </div>
        <div className="">
          {categories.map((item, index) => {
            if (category === item) {
              if (item === "all") {
                return data.length === 0 ? (
                  <div key={`${item}-${index}`}>No Data</div>
                ) : (
                  <Cards
                    message={message}
                    route={route}
                    cards={data}
                    key={`${item}-${index}`}
                    add={add}
                    showFavIcon={showFavIcon}
                  />
                );
              }
              if (item === "your favorites" && user !== null) {
                const favItems = favoriteItems;
                if (favItems.length === 0)
                  return (
                    <div
                      className="flex flex-col items-center justify-center py-4"
                      key={`${item}-${index}`}
                    >
                      <img
                        className="w-14 h-14"
                        src="/images/icons/noFavorites.svg"
                        alt="No Favorites"
                      />
                      <p className="text-center max-w-sm">
                        You don’t have any favorites. please click on like icon
                        to add your favorite list.
                      </p>
                    </div>
                  );
                else
                  return (
                    <Cards
                      message={message}
                      route={route}
                      cards={favItems}
                      key={`${item}-${index}`}
                      add={add}
                      showFavIcon={showFavIcon}
                    />
                  );
              }
              if (item === "male" || item === "female") {
                const categoryData = data.filter(
                  (item) => item.gender === category
                );
                return categoryData.length === 0 ? (
                  <div key={`${item}-${index}`}>
                    No Avatars in this category
                  </div>
                ) : (
                  <Cards
                    message={message}
                    route={route}
                    cards={categoryData}
                    key={`${item}-${index}`}
                    add={add}
                    showFavIcon={showFavIcon}
                  />
                );
              } else {
                return data.length === 0 ? (
                  <div className="flex flex-col" key={`${item}-${index}`}>
                    <img src="/images/noData.jpg"></img>
                    <p className="m-auto">{`No ${category}`}</p>
                  </div>
                ) : (
                  <Cards
                    message={message}
                    route={route}
                    cards={data && data.filter((item) => item.tag === category)}
                    key={`${item}-${index}`}
                    add={add}
                    showFavIcon={showFavIcon}
                  />
                );
              }
            }
            return null;
          })}
        </div>
      </div>
    </div>
  );
};

export default Categories;
