import React from "react";
import { Link } from "react-router-dom";

const Logo = ({ taglineFontStyle = "text-pk" }) => {
  const taglineStyle = `text-xs font-raleway font-medium ${taglineFontStyle}`;
  return (
    <Link className="flex flex-col" to="/home">
      <p className="text-2xl text-pk font-raleway font-medium">facemash</p>
      <p className={taglineStyle}>powered by artefax.ai</p>
    </Link>
  );
};

export default Logo;
