import React, { useEffect, useState } from "react";
import Caurousal from "./Carousal";
import InputBox from "./InputBox";
import Hoverbutton from "./Hoverbutton";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getProductById } from "../../network/ProductsAPI";
import { useAuth } from "../../context/AuthContext";
import { useLoader } from "../../context/LoaderContext";
import { useCart } from "../../context/CartContext";
import { useFaceMash } from "../../context/FaceMashContext";
import { addCart } from "../../network/CartAPI";
import Navbar from "../layout/Navbar";
import BackButton from "./BackButton";
import { toast, Toaster } from "react-hot-toast";
import Footer from "../layout/footer";
import ColorBox from "./ColorBox";

const ProductDetail2 = () => {
  const [productData, setProductData] = useState([]);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [caption, setCaption] = useState("");

  const images = [{ objectName: "shirt", url: "./images/icons/tshirt.jpg" }];
  const { id, size, color } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  const { loader, setLoader, Spinner } = useLoader();
  const {
    cart,
    setCart,
    setCheckOutProducts,
    setCartSize,
    finalProductImage,
    setFinalProductImage,
  } = useCart();
  const { facemashImg, setFaceMashImg } = useFaceMash();

  const handleSizeChange = (size) => {
    setSelectedSize(size);
    // Update the URL with the selected size
    if (selectedColor) {
      navigate(`/merchimage/${id}/${selectedColor}/${size}`);
    } else {
      navigate(`/merchimage/${id}/${size}`);
    }
  };

  const handleColorChange = (color) => {
    setSelectedColor(color);
    navigate(`/merchimage/${id}/${color}/${selectedSize}`);
  };

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const facemashLocal = JSON.parse(localStorage.getItem("facemash"));
    const finalProductImageLocal = JSON.parse(
      localStorage.getItem("finalProductImage")
    );
    if (size && size !== "null") setSelectedSize(size);
    if (color) setSelectedColor(color);
    if (finalProductImageLocal) {
      setFinalProductImage(finalProductImageLocal);
    }
    setFaceMashImg(facemashLocal);
    if (userData === null) navigate("/home");
    else {
      setLoader(true);
      const getProductDetailsById = async (user) =>
        await getProductById(user, id);
      getProductDetailsById(userData)
        .then((res) => {
          setProductData(res.data.data);
          setLoader(false);
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    }
  }, []);

  const captionChangeHandler = (e) => {
    setCaption(e.target.value);
  };

  const addCartHandler = () => {
    const ItemData = {
      name: productData.name,
      product_id: productData._id,
      quantity: 1,
      product_url: productData.ImageUrl[0],
      price: productData.price,
      facemash_img_url: facemashImg,
      size: selectedSize,
      caption: caption,
    };
    console.log("Data passing to api", ItemData);
    addCart(user, ItemData)
      .then((res) => {
        console.log(res);
        setCartSize((prevSize) => prevSize + 1);
        toast.success("Added to cart successfully!");
        // setSelectedSize(null);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to add to cart!");
        // setSelectedSize(null);
      });
    console.log(ItemData);
  };
  const getImagesList = () => {
    if (productData && productData.ImageUrl) {
    }
  };
  return loader === true ? (
    <Spinner />
  ) : (
    <div>
      <Navbar>
        <div className="flex-1">
          <BackButton />
          <p className="text-base text-black">Details</p>
        </div>
      </Navbar>
      <div className="flex flex-col  gap-4 m-4 font-sans">
        <Toaster toastOptions={{ duration: 4000 }} />
        <Caurousal
          images={
            productData &&
            productData.ImageUrl &&
            [{ image_url: finalProductImage }].concat(
              productData.ImageUrl.map((url) => ({ image_url: url }))
            )
          }
          imageStyles="object-fill"
        />
        <div className="flex-flex-col gap-4">
          <h1 className="font-Raleway text-lg font-medium	 text-primary font-medium">
            {productData && productData.name && productData.name.toLowerCase()}
          </h1>
          <h1 className="font-sans text-[16px] font-bold	">
            {productData.price} USD
          </h1>
          <p className="font-sans text-[12px] font-normal text-[#595959]">
            price excludes shipping and taxes which will be calculated on
            checkout.
          </p>
        </div>
        {productData && productData.type === "Clothing" && (
          <div className="flex flex-col">
            <h1 className="text-primary font-Raleway font-medium text-[18px]">
              Color
            </h1>
            <div className="flex flex-row ml-2 gap-2">
              <ColorBox
                color="black"
                isSelected={"black" === selectedColor}
                selectColor={handleColorChange}
              />
              <ColorBox
                color="gray-300"
                isSelected={"gray-300" === selectedColor}
                selectColor={handleColorChange}
              />
              <ColorBox
                color="blue-900"
                isSelected={"blue-900" === selectedColor}
                selectColor={handleColorChange}
              />
            </div>
            {!selectedColor && (
              <span className="text-red-400">* select color to proceed</span>
            )}
          </div>
        )}
        <div className="flex flex-col gap-2">
          <h1 className="font-Raleway text-[18px] font-medium text-primary font-medium">
            Size
          </h1>
          <div className="gap-4 flex">
            {productData &&
              productData.size &&
              productData.size.map((eachSize) => (
                <button
                  key={eachSize}
                  className={
                    "py-2 px-4  border border-primary rounded-md text-[12px] font-bold hover:bg-primary hover:text-white " +
                    (selectedSize === eachSize
                      ? "text-white bg-primary"
                      : "text-primary font-medium ")
                  }
                  onClick={() => handleSizeChange(eachSize)}
                >
                  {eachSize + (productData.type === "Bottles" ? " ml" : "")}
                </button>
              ))}
          </div>
        </div>
        {!selectedSize && (
          <span className="text-red-400">* select size to proceed</span>
        )}
        <div className="flex flex-col gap-2">
          <h1 className="font-Raleway text-18 text-primary font-medium">
            why we love it
          </h1>
          <ul className="ml-4 flex flex-col gap-2 custom-list">
            {productData &&
              productData.description &&
              productData.description.split(".").map((line, index) => {
                if (line.length === 0) return null;
                else {
                  return (
                    <li
                      key={index}
                      className="font-sans text-[15px] font-normal"
                    >
                      {line}
                    </li>
                  );
                }
              })}
          </ul>
        </div>
        <div className=" flex flex-col gap-2 ">
          <p className="font-Raleway  text-primary font-medium text-[18px] ">
            add caption
            <sub className="font-Raleway  text-[12px]  text-primary font-medium  ">
              {" "}
              (Optional, max 30 characters)
            </sub>
          </p>
          <InputBox
            customClassName=" border-[1px] border-primary pl-2  w-full h-[40px] rounded-md"
            customPlaceholder="bob the builder"
            onChange={captionChangeHandler}
            value={caption}
          />
        </div>
        <div className=" grid grid-cols-2 gap-2 my-4">
          <div onClick={() => (selectedSize ? addCartHandler() : "")}>
            <Hoverbutton
              customClassName="col-span-1 rounded-md border-[1px] border-primary text-[14px] font-mediu,"
              text="add to Cart"
            />
          </div>
          <div
            onClick={() => {
              if (selectedSize) {
                const itemData = {
                  id: productData._id + 10,
                  product_id: productData._id,
                  product_url: productData.ImageUrl[0],
                  quantity: 1,
                  price: productData.price | 25,
                  facemash_img_url: facemashImg,
                  size: selectedSize,
                  caption: caption,
                };
                setCheckOutProducts([itemData]);
                navigate("/checkcart");
              }
            }}
            className="cursor-pointer"
          >
            <Hoverbutton
              customClassName="col-span-1 rounded-md border-[1px] border-primary text-[14px] font-mediu, bg-primary text-white"
              text="buy now"
            />
          </div>
        </div>
      </div>
      <Footer />
      <style>
        {`
          .list-disc-pink li::before {
            content: "•"; 
            color:#AB1FBC; 
            margin-right: 8px;
          }
          .custom-list li {
            list-style-image: url('/images/BulletPoint.png');
          }
        `}
      </style>
    </div>
  );
};

export default ProductDetail2;
