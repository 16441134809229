import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function BackButton({ size = 30, path = -1 }) {
  const navigate = useNavigate();
  const clickHandler = () => {
    navigate(path);
  };
  return (
    <div>
      <FaArrowLeft
        className="text-white p-2 bg-pk m-2 rounded-md cursor-pointer"
        size={size}
        onClick={clickHandler}
      />
    </div>
  );
}

export default BackButton;
