import React, { createContext, useContext, useState } from "react";
import Spinner from "../components/common/Loader";

const LoaderContext = createContext();

export const useLoader = () => useContext(LoaderContext);

export const LoaderProvider = ({ children }) => {
  const [loader, setLoader] = useState(false);

  const value = {
    loader,
    setLoader,
    Spinner,
  };
  return (
    <LoaderContext.Provider value={value}>{children}</LoaderContext.Provider>
  );
};
