import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import PaymentFailureModal from "../modals/PaymentFailureModal";
import PaymentSuccessModal from "../modals/PaymentSuccessModal";

function CheckoutForm({ clientSecret }) {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [failureModalOpen, setFailureModalOpen] = useState(false);

  // Function to handle successful payment
  const handlePaymentSuccess = () => {
    setSuccessModalOpen(true);
  };

  // Function to handle failed payment
  const handlePaymentFailure = (message) => {
    setFailureModalOpen(true);
  };

  // Function to close modals
  const closeModal = () => {
    setSuccessModalOpen(false);
    setFailureModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements || !clientSecret) {
      return;
    }

    setIsLoading(true);

    try {
      const cardElement = elements.getElement(CardElement);
      const { paymentIntent, error } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: {
            card: cardElement,
          },
        }
      );

      if (error) {
        setMessage(error.message);
        handlePaymentFailure(error.message);
      } else if (paymentIntent.status === "succeeded") {
        setMessage("Payment successful!");
        handlePaymentSuccess();
        // navigate("/myuploads");
      } else {
        setMessage("Payment processing...");
        // Call the confirm endpoint to complete the payment
        await axios.post(
          `${process.env.REACT_APP_BASE_URL}/v1/payment_intents/${paymentIntent.id}/confirm`
        );
        setMessage("Payment confirmed!");
        handlePaymentSuccess();
      }
    } catch (error) {
      console.error("Error:", error);
      setMessage(error.message);
      handlePaymentFailure(error.message);
    }

    setIsLoading(false);
  };

  return (
    <form
      id="payment-form"
      onSubmit={handleSubmit}
      className="flex justify-center items-center h-screen w-screen"
    >
      <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-lg">
        <h2 className="text-2xl font-semibold mb-6">Payment Details</h2>
        <div className="mb-6">
          <CardElement className="border border-gray-300 rounded p-3" />
        </div>
        <button
          disabled={isLoading}
          id="submit"
          className={`bg-blue-600 text-white rounded-md font-semibold py-3 px-4 w-full transition duration-200 ease-in-out shadow-md hover:filter hover:brightness-115 ${
            isLoading && "opacity-50 cursor-not-allowed"
          }`}
        >
          {isLoading ? (
            <div className="flex justify-center items-center">
              <div className="spinner rounded-full w-5 h-5 border-2 border-white mr-2"></div>
              <span>Processing...</span>
            </div>
          ) : (
            "Pay Now"
          )}
        </button>
        {/* Show any error or success messages */}
        {/* {message && (
          <div
            id="payment-message"
            className={`text-center text-sm mt-4 ${
              message.startsWith("Payment successful!")
                ? "text-green-600"
                : "text-red-600"
            }`}
          >
            {message}
          </div>
        )} */}
        <PaymentSuccessModal
          isOpen={successModalOpen}
          closeModal={closeModal}
        />
        <PaymentFailureModal
          isOpen={failureModalOpen}
          closeModal={closeModal}
          message={message}
        />
      </div>
    </form>
  );
}

export default CheckoutForm;

// import React, { useState } from "react";
// import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

// function CheckoutForm({ clientSecret }) {
//   const stripe = useStripe();
//   const elements = useElements();
//   const [message, setMessage] = useState("");
//   const [isLoading, setIsLoading] = useState(false);

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (!stripe || !elements || !clientSecret) {
//       return;
//     }

//     setIsLoading(true);

//     try {
//       const cardElement = elements.getElement(CardElement);
//       const { error } = await stripe.confirmCardPayment(clientSecret, {
//         payment_method: {
//           card: cardElement,
//         },
//       });

//       if (error) {
//         setMessage(error.message);
//       } else {
//         setMessage("Payment successful!");
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       setMessage(error.message);
//     }

//     setIsLoading(false);
//   };

//   return (
//     <form
//       id="payment-form"
//       onSubmit={handleSubmit}
//       className="flex justify-center items-center h-screen w-screen"
//     >
//       <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-lg">
//         <h2 className="text-2xl font-semibold mb-6">Payment Details</h2>
//         <div className="mb-6">
//           <CardElement className="border border-gray-300 rounded p-3" />
//         </div>
//         <button
//           disabled={isLoading}
//           id="submit"
//           className={`bg-blue-600 text-white rounded-md font-semibold py-3 px-4 w-full transition duration-200 ease-in-out shadow-md hover:filter hover:brightness-115 ${isLoading && 'opacity-50 cursor-not-allowed'}`}
//         >
//           {isLoading ? (
//             <div className="flex justify-center items-center">
//               <div className="spinner rounded-full w-5 h-5 border-2 border-white mr-2"></div>
//               <span>Processing...</span>
//             </div>
//           ) : (
//             "Pay Now"
//           )}
//         </button>
//         {/* Show any error or success messages */}
//         {message && (
//           <div
//             id="payment-message"
//             className={`text-center text-sm mt-4 ${message.startsWith("Payment successful!") ? "text-green-600" : "text-red-600"}`}
//           >
//             {message}
//           </div>
//         )}
//       </div>
//     </form>
//   );
// }

// export default CheckoutForm;

// // import React, { useEffect, useState } from "react";
// // import {
// //   CardElement,
// //   useStripe,
// //   useElements,
// // } from "@stripe/react-stripe-js";

// // function CheckoutForm() {
// //   const stripe = useStripe();
// //   const elements = useElements();
// //   const [message, setMessage] = useState(null);
// //   const [isLoading, setIsLoading] = useState(false);
// //   const [clientSecret, setClientSecret] = useState(null);

// //   useEffect(() => {
// //     const fetchData = async () => {
// //       try {
// //         const response = await fetch("https://partly-grateful-flounder.ngrok-free.app/v1/payment_intents", {
// //           method: "POST",
// //           headers: {
// //             "Content-Type": "application/json",
// //           },
// //           body: JSON.stringify({
// //             amount: 111,
// //             currency: "usd",
// //           }),
// //         });
// //         const data = await response.json();
// //         setClientSecret(data.client_secret);
// //       } catch (error) {
// //         console.error("Error fetching client secret:", error);
// //         setMessage("Something went wrong while fetching client secret.");
// //       }
// //     };

// //     fetchData();
// //   }, []);

// //   const handleSubmit = async (e) => {
// //     e.preventDefault();

// //     if (!stripe || !elements || !clientSecret) {
// //       return;
// //     }

// //     setIsLoading(true);

// //     try {
// //       const cardElement = elements.getElement(CardElement);
// //       const { error } = await stripe.confirmCardPayment(clientSecret, {
// //         payment_method: {
// //           card: cardElement,
// //         },
// //       });

// //       if (error) {
// //         setMessage(error.message);
// //       } else {
// //         setMessage("Payment successful!");
// //       }
// //     } catch (error) {
// //       console.error("Error:", error);
// //       setMessage(error.message);
// //     }

// //     setIsLoading(false);
// //   };

// //   return (
// //     <form
// //     id="payment-form"
// //     onSubmit={handleSubmit}
// //     className="flex justify-center items-center h-screen w-screen"
// //   >
// //     <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-lg">
// //       <h2 className="text-2xl font-semibold mb-6">Payment Details</h2>
// //       <div className="mb-6">
// //         <CardElement className="border border-gray-300 rounded p-3" />
// //       </div>
// //       <button
// //         disabled={isLoading}
// //         id="submit"
// //         className={`bg-blue-600 text-white rounded-md font-semibold py-3 px-4 w-full transition duration-200 ease-in-out shadow-md hover:filter hover:brightness-115 ${isLoading && 'opacity-50 cursor-not-allowed'}`}
// //       >
// //         {isLoading ? (
// //           <div className="flex justify-center items-center">
// //             <div className="spinner rounded-full w-5 h-5 border-2 border-white mr-2"></div>
// //             <span>Processing...</span>
// //           </div>
// //         ) : (
// //           "Pay Now"
// //         )}
// //       </button>
// //       {/* Show any error or success messages */}
// //       {message && (
// //         <div
// //           id="payment-message"
// //           className={`text-center text-sm mt-4 ${message.startsWith("Payment successful!") ? "text-green-600" : "text-red-600"}`}
// //         >
// //           {message}
// //         </div>
// //       )}
// //     </div>
// //   </form>
// //   );
// // }

// // export default CheckoutForm;
