import React, { createContext, useContext, useEffect, useState } from "react";

const CartContext = createContext();

const useCart = () => useContext(CartContext);

const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const [cartSize, setCartSize] = useState(0);
  const [checkOutProducts, setcheckOutProducts] = useState([]);
  const [paymentData, setpaymentData] = useState();
  const [finalProductImage, setfinalProductImage] = useState(null);

  const setCheckOutProducts = (checkoutproducts) => {
    setcheckOutProducts(checkoutproducts);
    localStorage.setItem("checkoutproducts", JSON.stringify(checkoutproducts));
  };
  const setPaymentData = (checkoutProductsData) => {
    setpaymentData(checkoutProductsData);
    localStorage.setItem("paymentData", JSON.stringify(checkoutProductsData));
  };
  const setFinalProductImage = (finalProductImage) => {
    setfinalProductImage(finalProductImage);
    localStorage.setItem(
      "finalProductImage",
      JSON.stringify(finalProductImage)
    );
  };

  const value = {
    cart,
    setCart,
    cartSize,
    checkOutProducts,
    setCheckOutProducts,
    paymentData,
    setPaymentData,
    setCartSize,
    finalProductImage,
    setFinalProductImage,
  };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

export { useCart, CartProvider };
