import React, { useEffect, useState } from "react";
import Logo from "../common/Logo";
import { IoMdCart } from "react-icons/io";
import { FaUserCircle } from "react-icons/fa";
import { useAuth } from "../../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { useCart } from "../../context/CartContext";
import { getCart } from "../../network/CartAPI";

function Navbar({ children }) {
  const [showDropdown, setShowDropdown] = useState(false);
  const { cart, setCart, cartSize, setCartSize } = useCart();
  const { user, login, logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    login(userData);
    const cartData = localStorage.getItem("cart");
    if (cartData) {
      setCart(JSON.parse(cartData));
    }
  }, []);

  useEffect(() => {
    if (user !== null) {
      getCart(user).then((res) => {
        if (res.data.data[0].products)
          setCartSize(res.data.data[0].products.length);
        else setCartSize(0);
      });
    } else {
      setCartSize(0);
    }
  }, [user]);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleLogout = () => {
    localStorage.removeItem("userData");
    logout();
    setShowDropdown(false);
    navigate("/home");
  };

  const navigateUpload = () => {
    navigate("/myuploads");
  };
  // console.log("cartSize", cartSize);

  return (
    <div
      className="navbar sticky -top-1 z-50 bg-white w-full"
      style={{ boxShadow: "0 2px 4px 0 rgba(0,0,0,0.2)" }}
    >
      {children}
      <div className="flex-none gap-4">
        {user !== null && (
          <div
            onClick={navigateUpload}
            className="text-base text-black cursor-pointer"
          >
            Uploads
          </div>
        )}
        {user !== null && (
          <div className="dropdown dropdown-bottom dropdown-end">
            <div tabIndex={0} role="button">
              <FaUserCircle
                className="text-3xl !text-white bg-pk rounded-full cursor-pointer"
                onClick={toggleDropdown}
              />
            </div>
            <ul
              tabIndex={0}
              className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
            >
              <li className="font-[500]  text-md cursor-pointer">
                <a onClick={handleLogout}>Logout</a>
              </li>
            </ul>
          </div>
        )}
        <Link to="/cart" className="relative cursor-pointer">
          {cartSize > 0 && (
            <div className=" absolute -top-2 -right-1 text-xs h-4 w-4 flex items-center justify-center text-white bg-pk rounded-full">
              {cartSize}
            </div>
          )}
          <IoMdCart className="text-3xl !text-white bg-pk rounded-full p-1" />
        </Link>
      </div>
    </div>
  );
}

export default Navbar;
