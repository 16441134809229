import React, { useState } from "react";
import Card from "./Card";
import Button from "./button";

function Cards({
  cards = [],
  route = "",
  add = false,
  message = "",
  showFavIcon = false,
}) {
  const [showAll, setShowAll] = useState(false);
  const [cardsData, setCardsData] = useState(cards);
  const handleClick = () => {
    setShowAll(!showAll);
  };

  const displayData = showAll ? cardsData : cardsData.slice(0, 6);

  return (
    <div className="flex flex-col">
      <div className="grid grid-cols-12 gap-2">
        {displayData.map((card, index) => (
          <Card
            src={card.image_url}
            key={index}
            title={card.category}
            image_id={card._id}
            add={add}
            route={route}
            showFavIcon={showFavIcon}
          />
        ))}
      </div>
      {cards.length > 6 && !showAll && (
        <div className="max-w-48 w-full mx-auto my-5">
          <Button onClick={handleClick}>more</Button>
        </div>
      )}
    </div>
  );
}

export default Cards;
